<template>
  <nav
    class="navbar py-2 px-4 d-lg-flex d-flex align-items-center justify-content-between"
  >
    <div class="header-left">
      <a v-if="!backBtn" @click="navigateToQuizDesign" class="logo">
        <img
          src="/logo/logo.png"
          alt="Logo"
          style="
            cursor: pointer;
            max-height: 43px;
            max-width: 116.781px;
            width: 116.781px !important;
            min-width: 116.781px !important;
          "
          class="mini-sidebar-logo"
        />
      </a>

      <div v-else class="d-flex align-items-center flex-wrap">
        <div
          class="navbar__icon-container d-flex justify-content-center align-items-center rounded-lg flex-wrap"
          style="cursor: pointer"
          @click="navigateToQuizDesign"
        >
          <svg
            class="navbar__icon navbar__icon--left"
            width="8"
            height="14"
            viewBox="0 0 8 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.08004 0.993291C6.75337 0.666624 6.22671 0.666624 5.90004 0.993291L0.360039 6.53329C0.100039 6.79329 0.100039 7.21329 0.360039 7.47329L5.90004 13.0133C6.22671 13.34 6.75337 13.34 7.08004 13.0133C7.40671 12.6866 7.40671 12.16 7.08004 11.8333L2.25337 6.99996L7.08671 2.16662C7.4067 1.84662 7.40671 1.31329 7.08004 0.993291Z"
              fill="white"
            />
          </svg>
        </div>
        <a
          class="navbar__title ml-4"
          @click="navigateToQuizDesign"
          style="text-decoration: none; color: inherit; cursor: pointer"
          >{{ route.name }}
        </a>
      </div>
    </div>

    <div class="d-flex align-items-center">
      <div style="display: contents">
        <div>
          <h4 class="mb-0 d-none d-md-block" style="font-size: 16px">
            {{ UserAccountData.name || "No Name" }}
          </h4>
          <!-- <p class="mb-0 " style="font-size:12px">dugit.co.il</p> -->
        </div>
        <div class="ml-3">
          <b-dropdown right class="" id="DashboardNav">
            <template slot="button-content">
              <img
                class="profileImage"
                :src="ImageSrc(UserAccountData.image)"
                style="
                  width: 44px;
                  height: 44px;
                  border-radius: 50%;
                  border: 2px solid #ffffff;
                "
              />
            </template>
            <b-dropdown-item
              class="topbarLink d-flex align-items-center"
              @click="openAccountPage"
            >
              <span class="mr-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-user"
                >
                  <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                  <circle cx="12" cy="7" r="4" />
                </svg>
              </span>
              View Profile</b-dropdown-item
            >
            <b-dropdown-item
              v-if="showInviteUser"
              class="topbarLink d-flex align-items-center"
              @click="openInvitePage"
            >
              <span class="mr-2">
                <i class="fa-solid fa-users" style="font-size: 14px"></i>
              </span>
              Invite Users</b-dropdown-item
            >
            <!--  v-if="!UserAccountData.isShopifyUser" -->
            <b-dropdown-item
              v-if="
                UserAccountData.userType == 'web' ||
                UserAccountData.userType == 'bc'
              "
              class="topbarLink d-flex align-items-center"
              @click="openUpgrade()"
            >
              <span class="" style="margin-right: 5px">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 16 16"
                  fill="currentColor"
                >
                  <path
                    d="M13.3333 13.3337H2.66659C1.93021 13.3337 1.33325 12.7367 1.33325 12.0003V4.00033C1.33325 3.26395 1.93021 2.66699 2.66659 2.66699H13.3333C14.0696 2.66699 14.6666 3.26395 14.6666 4.00033V12.0003C14.6666 12.7367 14.0696 13.3337 13.3333 13.3337ZM2.66659 4.00033V12.0003H13.3333V4.00033H2.66659ZM10.3333 10.667C9.41278 10.667 8.66658 9.9208 8.66658 9.00033C8.66658 8.07985 9.41278 7.33366 10.3333 7.33366C11.2537 7.33366 11.9999 8.07985 11.9999 9.00033C11.9999 9.44235 11.8243 9.86628 11.5118 10.1788C11.1992 10.4914 10.7753 10.667 10.3333 10.667ZM7.66658 10.667C6.74611 10.667 5.99992 9.9208 5.99992 9.00033C5.99992 8.07985 6.74611 7.33366 7.66658 7.33366C8.02846 7.33395 8.38006 7.45396 8.66658 7.67499C8.24742 7.98641 8.00033 8.4778 8.00033 8.99999C8.00033 9.52218 8.24742 10.0136 8.66658 10.325C8.38015 10.5463 8.02853 10.6665 7.66658 10.667Z"
                  />
                </svg>
              </span>
              Invoice and Billing</b-dropdown-item
            >
            <b-dropdown-divider></b-dropdown-divider>
            <!-- -->

            <b-dropdown-item
              class="topbarLink d-flex align-items-center"
              @click="$router.push({ name: 'AccessToken' })"
            >
              <span class="mr-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-code"
                >
                  <polyline points="16 18 22 12 16 6"></polyline>
                  <polyline points="8 6 2 12 8 18"></polyline>
                </svg>
              </span>
              API</b-dropdown-item
            >
            <b-dropdown-item
              class="topbarLink d-flex align-items-center"
              @click="openSupport"
            >
              <span class="mr-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-help-circle"
                >
                  <circle cx="12" cy="12" r="10" />
                  <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3" />
                  <line x1="12" y1="17" x2="12.01" y2="17" />
                </svg>
              </span>
              Help Center</b-dropdown-item
            >
            <b-dropdown-divider
              v-if="UserAccountData.userType == 'web'"
            ></b-dropdown-divider>
            <b-dropdown-item
              v-if="UserAccountData.userType == 'web'"
              class="topbarLink d-flex align-items-center"
              @click="logOut()"
            >
              <b-icon-box-arrow-right class="mr-2"></b-icon-box-arrow-right>
              Logout
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
      <div></div>
    </div>
  </nav>
  <!-- nav end-->
</template>

<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    backBtn: Boolean,
    route: {
      Type: Object,
      default: () => ({
        name: "Dashboard",
        path: "/",
      }),
    },
  },
  data() {
    return {};
  },
  components: {},
  computed: {
    showInviteUser() {
      const isWorkSpaceUser = localStorage.getItem("isWorkSpaceUser");
      return (
        isWorkSpaceUser !== "true" && this.UserAccountData.userType == "web"
      );
    },
    ...mapGetters(["GetTokenFromMetaTag", "getNavbarData"]),
    UserAccountData() {
      return this.getNavbarData;
    },
    isProductPage() {
      if (
        this.$route.name == "Product" ||
        this.$route.name == "EditProduct" ||
        this.$route.name == "AddProduct" ||
        this.$route.fullPath == "/manage/account"
      )
        return true;

      return false;
    },
  },
  async mounted() {
    // this.loadNavbarData()
  },
  methods: {
    openUpgrade() {
      this.$router.push({ name: "Invoice" });
    },
    navigateToQuizDesign() {
      const { path } = this.route;

      if (path && path != "") {
        this.$router.push(path);
        return;
      }
      this.$router.push("/manage/quizzes").then(() => {
        this.$router.go(0);
      });
    },
    ...mapActions(["loadNavbarData"]),
    async logOut() {
      try {
        const response = await axios.post("/logout");
        if (response.status == 200) {
          const main_website_url = process.env.VUE_APP_MAIN_WEBSITE;
          let RedirectURL = `${main_website_url}`;
          localStorage.removeItem("user_access_token");

          // window.open(RedirectURL,"_self");
          window.location.href = RedirectURL;
        }
      } catch (error) {
        this.$toasted.show("Error occured", {
          theme: "toasted-primary",
          position: "bottom-center",
          duration: 2000,
        });
        throw error;
      }
    },

    loadUserFiles() {
      this.$refs.FilesModalRef.OpenModal();
    },
    openSupport() {
      window.open("https://support.quizell.com/");
    },
    openAccountPage() {
      if (this.$route.fullPath !== "/manage/account/user_profile")
        this.$router.push("/manage/account/user_profile");
    },
    openInvitePage() {
      if (this.$route.fullPath !== "/manage/account/roles-permissions")
        this.$router.push("/manage/account/roles-permissions");
    },
    ImageSrc(img) {
      if (img == "uploads/profile/1841662374867.jpg") {
        return "https://cdn.shopify.com/s/files/1/0554/9676/9614/files/default.png?v=1663245344";
      } else {
        return img;
      }
    },
  },
};
</script>

<style scoped>
.navbar {
  background: #4d1b7e;
  color: #fff;
  box-shadow: 0px 0px 15px rgb(10 4 17 / 20%);
  display: block;
  position: fixed;
  width: 100%;
  z-index: 112;
}
.DashboardMain {
  background-color: #e5e5e5 !important;
}
#DashboardNav >>> button {
  background: transparent;
  border: none;
  margin: 0;
  padding: 0;
}
#DashboardNav >>> button::after {
  display: none;
}
#DashboardNav >>> ul {
  background: #ffffff;
  box-shadow: 0px 10px 20px -3px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
}
#DashboardNav >>> li.topbarLink {
  color: #344054;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 5px;
}
#DashboardNav >>> li a:active {
  color: #fff;
  text-decoration: none;
  background-color: #4d1b7e;
}
a:hover {
  text-decoration: none !important;
}

.profileImage:hover {
  transition: transform 200ms ease;
  transform: scale(0.9);
}
</style>
